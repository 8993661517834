:root {
  --magento: #E00C6B;
  --pink: #FF1654;
  --peach: #FE5F55;
  --orange: #F37B20;
  --yellow: #D2CE3D;
  --purple: #341869;
  --navy: #003660;
  --dark-aqua: #148080;
  --light-aqua: #309897;
  --dark: #30323D;
  --span-dark: #3D3D3D;
  --overlay-gray: #40424F;
  --text-dark: #445561;
  --slate: #4D5061;
  --light-gray: #ECF2F6;
  --off-white: #FCFFFD;
  --white: #FFFFFF;

  --font: 'Quicksand', sans-serif;
  --secondary-font: 'Work Sans', sans-serif;
  --tertiary-font: 'Overpass', sans-serif;

  --standard-easing: cubic-bezier(0.4, 0.0, 0.2, 1);
}