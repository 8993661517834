html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--slate);
}

::-webkit-scrollbar-thumb {
  background-color: var(--yellow);
  border-radius: 8px;
  transition: background-color .2s var(--standard-easing);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--orange);
  transition: background-color .2s var(--standard-easing);
}